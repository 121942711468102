import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject, PLATFORM_ID, APP_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultHeader } from './shared-components/headers/default/default.component';
import { DefaultFooter } from './shared-components/footers/default/default.component';
import { HomePage } from './pages/home/home.component';

@NgModule({
	declarations: [
		AppComponent,
		DefaultHeader,
		DefaultFooter,
		HomePage
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		MatButtonModule,
		MatCardModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		@Inject(APP_ID) private appId: string
	  ) {
		const platform = isPlatformBrowser(platformId) ?
		  'in the browser' : 'on the server';
		// console.log(`Running ${platform} with appId=${appId}`);
	  }
}
