import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './pages/home/home.component';

const routes: Routes = [
	{
		path: '**',
		redirectTo: '/',
		pathMatch: 'full'
	},
	{
		path: '',
		component: HomePage
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
