import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'default-footer',
	templateUrl: './default.component.html',
	styleUrls: ['./default.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DefaultFooter {

	constructor() { }

}
