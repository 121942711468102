<header>
	<nav class="container-fluid">
		<div class="row justify-content-between bars">
			<img class="logo" src="/assets/logos/synvia_white.svg" />
			<i class="fal fa-bars fa-2x" (click)="expand()"></i>
		</div>
		<div class="row justify-content-end links" [class.expanded]="expanded">
			<a class="link" *ngFor="let tag of tags" (click)="goToTag(tag.tag)">
				{{ tag.name }}
			</a>
			<!-- <a class="link" target="_blank" href="https://portaldevagas.connekt.com.br/synvia/">inscreva-se</a> -->
		</div>
	</nav>
</header>
