<div class="overlay">
	<img class="vector" src="/assets/images/vector.svg" />
	<div class="row">
		<div class="col-sm-10 col-12 col-lg-12 flex">
			<img class="rocket" src="/assets/images/rocket.svg" />
			<div>
				<h2>PROGRAMA DE</h2>
				<h1>ESTÁGIO</h1>
			</div>
		</div>
		<div class="col-sm-10 col-12 col-lg-12">
			<h4>A sua jornada<br />começa aqui!</h4>
			<p>Programa estágio Synvia</p>
			<button mat-button type="button" class="opportunities" (click)="goToJobs()">Veja nossas vagas!</button>
		</div>
	</div>
</div>
<section class="home-1"></section>
<section class="home-2">
	<div class="container-fluid">
		<div class="row justify-content-between">
			<div class="col-lg-5 col-12">
				<div class="flex">
					<img class="logo-synvia" src="/assets/logos/synvia_black.svg" />
				</div>
				<p>Venha inovar, se descobrir e encarar desafios propondo novas soluções para as empresas Synvia.</p>
				<p>O ambiente de trabalho é dinâmico, proporcionando uma constante evolução. Aqui você é incentivado a participar, aprender e ter atitude.</p>
				<p>O programa foi desenhado com cuidado para dar grande visibilidade aos nossos estagiários, que terão contato com os níveis mais altos da empresa e grandes projetos.</p>
				<h3>Veja quais são as áreas com oportunidades disponíveis!</h3>
			</div>
			<div class="col-lg-5 col-12 img-lab">
				<img class="vector-arrow" src="/assets/images/dots-arrow.svg" />
				<img src="/assets/images/home2.png" />
			</div>
		</div>
		<img class="vector-l" src="/assets/images/dots-l.svg" />
	</div>
</section>
<section class="home-3">
	<img class="rocket-white" src="/assets/images/rocket-white.svg" />
	<div class="container-fluid" id="requirements">
		<div class="row">
			<div class="col-lg-11 col-12">
				<h1>Venha fazer parte da nossa missão de melhorar a vida de cada vez mais pessoas!</h1>
			</div>
			<div class="col-lg-8 col-12">
				<mat-card class="requirements">
					<div class="row">
						<div class="col-12">
							<h3>Pre-requisitos</h3>
						</div>
						<div class="col-lg-6 col-12 flex">
							<i class="fal fa-book fa-3x"></i>
							<div>
								<h4>Estar cursando</h4>
								<p>Ensino superior</p>
							</div>
						</div>
						<div class="col-lg-6 col-12 flex">
							<i class="fal fa-clock fa-3x"></i>
							<div>
								<h4>Disponibilidade</h4>
								<p>30h semanais com flexibilidade de horário</p>
							</div>
						</div>
						<div class="col-lg-6 col-12 flex">
							<i class="fal fa-map-marker-alt fa-3x"></i>
							<div>
								<h4>Localização</h4>
								<p>Campinas, Paulínia e região</p>
							</div>
						</div>
					</div>
					<div class="flex btn">
						<button mat-button type="button" class="opportunities" (click)="goToJobs()">Veja nossas vagas!</button>
					</div>
				</mat-card>
			</div>
			<div class="col-lg-4 col-12 steps">
				<h3>Etapas</h3>
				<div class="flex">
					<div class="white-circle">1</div>
					<span>Inscrição</span>
				</div>
				<div class="flex">
					<div class="white-circle">2</div>
					<span>Dinâmica Virtual</span>
				</div>
				<div class="flex">
					<div class="white-circle">3</div>
					<span>Entrevista</span>
				</div>
				<div class="flex">
					<div class="white-circle">4</div>
					<span>Oferta e Processo Admissional</span>
				</div>
				<div class="flex">
					<div class="white-circle">5</div>
					<span>Onboarding</span>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="home-4">
	<div class="container-fluid" id="benefits">
		<div class="row">
			<div class="col-lg-7 col-12 flex justify-content-center">
				<div class="values">
					<h3>Nossos valores são os pilares que sustentam a empresa e para nós é importantíssimo que você se identifique com eles:</h3>
					<div class="row">
						<div class="col-4">
							<i class="fal fa-handshake-alt fa-4x"></i>
							<p>Integridade</p>
						</div>
						<div class="col-4">
							<i class="fal fa-pennant fa-4x"></i>
							<p>Sentimento<br />de dono</p>
						</div>
						<div class="col-4">
							<i class="fal fa-lightbulb-on fa-4x"></i>
							<p>Inovação e Excelência</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-5 col-12 flex justify-content-lg-end">
				<img src="/assets/images/home-values.png" />
			</div>
		</div>
		<img class="vector-benefits" src="/assets/images/benefits.svg" />
		<div class="row">
			<div class="col-lg-5 col-12 flex">
				<img src="/assets/images/home-benefits.png" />
			</div>
			<div class="col-lg-7 col-12 flex justify-content-center">
				<img class="play-benefits" src="/assets/images/play1.svg" />
				<mat-card class="benefits">
					<h3>Benefícios</h3>
					<ul>
						<li>Bolsa estágio de <b>R$1.400,00</b> ou <b>R$1.600,00</b> (para último ano)</li>
						<li>Vale alimentação de <b>R$160,00</b></li>
					</ul>
				</mat-card>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-7 col-12 flex justify-content-center">
				<div class="team">
					<h3>Por que ser do time Synvia?</h3>
					<ul>
						<li>Nossas empresas são referência no mercado de biotecnologia e saúde</li>
						<li>Somos mais de 300 colaboradores</li>
						<li>Trabalhamos com equipamentos e softwares de ponta, em todos os times</li>
						<li>Nossas sedes foram projetadas para trazer conforto para nossos colaboradores</li>
						<li>Aproveite seu intervalo para relaxar em um ambiente descontraído!</li>
						<li>No dress code: todos os gostos e estilos são bem-vindos</li>
					</ul>
					<h4>Aqui na Synvia, ser estagiário não é servir cafézinho ou preencher planilha</h4>
					<h4>Você vai participar e se envolver em muitos processos!</h4>
					<img class="play-team" src="/assets/images/play1.svg" />
				</div>
			</div>
			<div class="col-lg-5 col-12 flex justify-content-lg-end">
				<img src="/assets/images/home-team.png" />
			</div>
		</div>
	</div>
</section>
<section class="home-5">
	<div class="blend"></div>
</section>
<section class="home-6">
	<div class="container-fluid">
		<div class="row interns">
			<div class="col-lg-4 col-12">
				<i class="fas fa-quote-left fa-5x"></i>
				<img src="/assets/images/intern1.png" />
				<p class="name">ALINE PILON</p>
				<p class="sector">Comunicação e Marketing</p>
				<p class="desc">Trabalho no Grupo Synvia desde 2018, quando entrei como estagiária de criação. Foi uma grande oportunidade para meu crescimento pessoal e profissional. Hoje, atuo como Diretora de Arte e sou muito grata por todo aprendizado e apoio que adquiri nesses anos.</p>
			</div>
			<div class="col-lg-4 col-12">
				<i class="fas fa-quote-left fa-5x"></i>
				<img src="/assets/images/intern2.png" />
				<p class="name">THALISSON RODRIGUES</p>
				<p class="sector">Inovação e TI</p>
				<p class="desc">Ter sido estagiário na Synvia foi uma ótima oportunidade, onde pude crescer e me desenvolver profissionalmente. Sou grato com cada aprendizado e com as novas habilidades que adquiro durante o trabalho.</p>
			</div>
			<div class="col-lg-4 col-12">
				<i class="fas fa-quote-left fa-5x"></i>
				<img src="/assets/images/intern3.png" />
				<p class="name">LETÍCIA CERQUEIRA GRILO</p>
				<p class="sector">Jurídico</p>
				<p class="desc">Estagiar na área jurídica da Synvia é ter a oportunidade de se aprimorar profissionalmente, com foco na aprendizagem, inovação e colaboração para impulsionar a sua carreira de modo assertivo.  A oportunidade de trabalhar com profissionais dispostos a contribuir no seu desenvolvimento é realmente muito gratificante.</p>
			</div>
		</div>
	</div>
</section>
<!-- <section class="home-6">
	<div class="container-fluid">
		<div class="row justify-content-center">
			<h2>Faça parte do nosso time!</h2>
			<h3>Inscreva-se já no Programa de Estágio Synvia</h3>
			<button mat-button type="button" class="opportunities" (click)="goToJobs()">Veja nossas vagas!</button>
		</div>
	</div>
</section> -->
<section class="home-7">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<h2>Faça parte do nosso time!</h2>
				<h3>Vagas disponíveis</h3>
			</div>
		</div>
	</div>
</section>
<section class="home-8">
	<div class="container-fluid" id="jobs">
		<div class="row">
			<div class="col-12">
				<h3>Laboratorial</h3>
				<ul>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/ca2dbdb3-db28-424a-8075-1b1dd3098df4" target="_blank">Equivalência Farmacêutica</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/6d90ff4b-16b5-4162-8fe8-e20c952819dd" target="_blank">Laboratório Biologia Molecular e Analises Clinicas</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/a23db57f-0959-4187-8d96-fdbb3dcd9874" target="_blank">Laboratório de Bioequivalência</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/9f9fe95d-37ff-4133-aacb-8838eed967e5" target="_blank">Laboratório Toxicológico</a></li>
				</ul>
			</div>
			<div class="col-12">
				<h3>Administrativas</h3>
				<ul>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/bd55012a-f1c4-44d5-b07d-abf718fa4dc5" target="_blank">Atendimento</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/e58d616d-deb6-481d-aa89-4b051bed8d4a" target="_blank">Comercial</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/add79fd2-1bac-4b33-8ca3-a7629fcd55f2" target="_blank">Comunicação & Marketing</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/5e6641e5-10df-4350-899e-103f28726ab5" target="_blank">Desenvolvimento de Sistemas</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/fa7a0a6d-91c8-4036-a19d-ac9a75b8b8b3" target="_blank">Financeiro</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/88fa76e8-2d85-446a-8666-50b756d66db9" target="_blank">Jurídico</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/38ce8780-3f63-4daa-b8a1-b2ce1c6fd734" target="_blank">Logística</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/8ade5de0-e7d2-4b07-a681-79bd3a71a4d5" target="_blank">Recursos Humanos - Recrutamento & Seleção</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/91ac8a64-71a4-4192-b9d8-0ddc9dd6446e" target="_blank">Suprimentos</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/7b0f604a-89dd-4009-9928-572007dd9587" target="_blank">Tecnologia da Informação</a></li>
				</ul>
			</div>
			<div class="col-12">
				<h3>Operações</h3>
				<ul>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/2b14b9df-6e69-424a-a23c-e0678989a44a" target="_blank">Assuntos Regulatórios</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/ac537067-a2fe-4739-a085-87a8ed40e218" target="_blank">Data Science</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/6e0e8cc7-4151-4b0f-8125-df1ac0d1b136" target="_blank">Departamento Médico - Enfermagem</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/3fc52a3e-26b3-4e5b-8fe5-4eef3757a64f" target="_blank">Documentação Técnica</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/8b2bf339-6a03-41e9-8cec-2b1294ad9505" target="_blank">Garantia da Qualidade</a></li>
					<li><a href="https://vagas.connekt.com.br/oportunidades/detalhe/ac930672-0407-435e-8472-a04ebdcb8aa8" target="_blank">Pesquisa Clínica</a></li>
				</ul>
			</div>
		</div>
	</div>
</section>
<section class="home-9">
	<div class="container-fluid" id="companies">
		<div class="row">
			<div class="col-12">
				<h2>Nossas<br />empresas:</h2>
			</div>
			<div class="col-lg-4 col-12 flex justify-content-lg-end">
				<div class="company-box">
					<img src="/assets/images/home-caep.png" />
					<img class="company-logo caep" src="/assets/logos/caep.svg" />
					<p>
						Maior centro de Equivalência e Bioequivalência Farmacêutica da América Latina, o CAEP ja conduziu mais de mil projetos de pesquisa de medicamentos para as maiores indústrias farmacêuticas do Brasil e do mundo.
					</p>
					<a href="http://caeplab.com.br/" target="_blank">caeplab.com.br ></a>
				</div>
			</div>
			<div class="col-lg-4 col-12 flex justify-content-lg-center">
				<div class="company-box">
					<img src="/assets/images/home-caeptox.png" />
					<img class="company-logo caeptox" src="/assets/logos/caeptox.svg" />
					<p>
						O CAEPTOX é hoje o maior laboratório brasileiro de análises toxicológicas, com capacidade para analisar mais de 10.000 amostras por dia. Nossos exames são utilizados para renovação de CNH e contratação de motoristas profissionais. Assim ajudamos a deixar o trânsito das estradas do Brasil muito mais seguro.
					</p>
					<a href="https://caeptox.com.br/" target="_blank">caeptox.com.br ></a>
				</div>
			</div>
			<div class="col-lg-4 col-12 flex">
				<div class="company-box">
					<img src="/assets/images/home-synova.png" />
					<img class="company-logo synova" src="/assets/logos/synova.svg" />
					<p>
						A Synova Health é a nossa CRO Full Service que se destaca apoiando a indústria farmacêutica em todas as suas necessidades relacionadas ao registro de novos produtos. Nosso alcance global aliado à nossa expertise local permitem à Synova oferecer os melhores serviços, desde estudos clínicos fases I-IV a regulatórios e Data Science.
					</p>
					<a href="https://www.synovahealth.com/" target="_blank">synovahealth.com ></a>
				</div>
			</div>
		</div>
	</div>
</section>