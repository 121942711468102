import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'home-page',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HomePage {

	constructor() { }

	goToJobs(){
		// window.open('https://portaldevagas.connekt.com.br/synvia/', '_blank');
		const element = document.getElementById('jobs');
		element.scrollIntoView({ behavior: 'smooth' });
	}

}
