import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'default-header',
	templateUrl: './default.component.html',
	styleUrls: ['./default.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DefaultHeader {

	expanded = false;
	tags = [
		{ name: 'pre-requisitos', tag: 'requirements' },
		{ name: 'benefícios', tag: 'benefits' },
		{ name: 'nossas empresas', tag: 'companies' },
		{ name: 'inscreva-se', tag: 'jobs' }
	];

	constructor() { }

	goToTag(tag) {
		const element = document.getElementById(tag);
		element.scrollIntoView({ behavior: 'smooth' });
	}

	expand() {
		this.expanded = !this.expanded;
	}

}
