<footer>
	<div class="container-fluid">
		<div class="row">
			<div class="col-sm-6 col-12 flex justify-content-sm-end">
				<img class="logo-synvia" src="/assets/logos/synvia_white.svg" />
			</div>
			<div class="col-sm-6 col-12">
				<div>
					<a href="https://pt-br.facebook.com/synviagroup/" target="_blank"><i class="fab fa-facebook-square fa-2x"></i></a>
					<a href="https://www.instagram.com/synviagroup/" target="_blank"><i class="fab fa-instagram fa-2x"></i></a>
					<a href="https://www.linkedin.com/company/synvia/" target="_blank"><i class="fab fa-linkedin fa-2x"></i></a>
				</div>
				<p>
					R. Mário Giordano, 146 - Antiga 33<br />
					Jardim America, Paulínia - SP, 13140-614
				</p>
				<a href="http://synvia.com/" target="_blank">www.synvia.com</a>
			</div>
		</div>
	</div>
</footer>
